import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Icon from "../custom-widgets/icon";
import List from "../custom-widgets/list";

// Helpers
import nameToId from "../../helpers/nameToId";

const ServiceCard = ({ card, index }) => {
  return (
    <div
      id={`${nameToId(card.name)}-service-anchor`}
      key={index}
      className="card h-100 overflow-hidden border-radius-12 border-0 card-box-shadow"
    >
      {card.image && card.image.imgVariable && (
        <GatsbyImage
          className={`card-image-top border-radius-bottom-0`}
          image={card.image.imgVariable}
          alt={card.image.altText}
        />
      )}
      <div className="card-body">
        {card.title ? (
          <h3 className="font-weight-semi-bold text-green-60" dangerouslySetInnerHTML={{ __html: card.title }} />
        ) : (
          <h3 className="font-weight-semi-bold text-green-60">{card.name}</h3>
        )}
        <p className="card-text">{card.description}</p>
        <List class="mb-0" {...card.listData} accountName={`${nameToId(card.name)}-list`} />
      </div>
      <div className="card-footer border-top-0 pt-0 bg-white">
        <a
          id={`${nameToId(card.name)}-get-started-btn`}
          rel="noopener noreferrer"
          target="_blank"
          className="btn btn-primary mr-4 no-min-width"
          href={card.getStartedLink || "https://go.heartland.us/wafd#form"}
        >
          Get Started
        </a>
        {card.linkUrl && (
          <Link id={`${nameToId(card.name)}-learn-more-link`} to={card.linkUrl} className="text-decoration-none">
            Learn More
            <Icon name="arrow-right" class="ml-2"></Icon>
          </Link>
        )}
        {card.disclaimers && (
          <div id={`${nameToId(card.name)}-disclaimers-section`} className="text-sm text-gray-60 mt-4">
            {card.disclaimers.map((disclaimer, idx) => {
              return (
                <p key={disclaimer.id} className={card.disclaimers.length - 1 === idx ? "mb-0" : ""}>
                  {disclaimer.text}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
ServiceCard.defaultProps = {
  name: "Payment Processing Services",
  description: "Accept payments anywhere with the most up-to-date technology so you can help more customers.",
  listData: {
    items: [
      {
        id: 1,
        text: "Terminal Payments (card present)"
      },
      {
        id: 2,
        text: "E-commerce (card not present)"
      }
    ]
  },
  linkUrl: "/business-banking/business-services/payment-processing-services",
  image: {
    imgVariable: null,
    altText: "Woman checking out at vegetable stand with a mobile phone."
  }
};

